import QR from 'qrcode-svg';

export const generateQRCode = (text: string): string => {
  try {
    const qr = new QR({
      content: text,
      padding: 4,
      width: 100,
      height: 100,
      color: '#000000',
      background: '#ffffff',
      ecl: 'H',
    });

    const svgString = qr.svg();
    const encodedSVG = encodeURIComponent(svgString);
    return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
  } catch (error) {
    console.log('generateQRCode error', error);
    return '';
  }
};
