import { bi } from '../../utils/bi';
import preferredAppToKey from '../../utils/preferredAppToKey';
import model from './model';
import { getAppImages, getSiteName } from '../../api';

let data: any = null;
export { data };
function resizeImage(image: string, width: number, height: number) {
  if (
    (image.includes('.wixmp.com/') || image.includes('.wixstatic.com/')) &&
    !image.includes('/v1/fit/') &&
    !image.includes('/v1/fill/') &&
    !image.includes('::fit:') &&
    !image.includes('::fill:')
  ) {
    return `${image}/v1/fit/w_${width},h_${height},lg_0/coverImage.webp`;
  }
  return image;
}

export default model.createController(({ $bind, $w, $widget, flowAPI }) => {
  let isAppLoaded = false;
  $widget.onPropsChanged((oldProps, newProps) => {
    if (newProps.title !== oldProps.title) {
      $w('#title').text = newProps.title;
    }
    if (newProps.description !== oldProps.description) {
      $w('#description').text = newProps.description;
    }
  });

  function setText() {
    const { preferredApp, siteName } = data;
    const titleProp = $widget.props.title;
    $w('#title').text =
      titleProp !== 'default_prop'
        ? titleProp
        : flowAPI.translations.t(
            `download_my_app.widget.title${
              preferredApp === 'dine' ? '.dine' : ''
            }`,
          );
    // if ($w('#title').text === 'default_title') {
    //   // $w('#title').text = flowAPI.translations.t(
    //   //   `download_my_app.widget.title${preferredApp == 'dine' ? '.dine' : ''}`
    //   // );
    // }
    const descriptionProp = $widget.props.description;
    $w('#description').text =
      descriptionProp !== 'default_prop'
        ? descriptionProp
        : flowAPI.translations.t(
            `download_my_app.widget.description.${preferredApp}`,
            { siteName },
          );
    // $w('#description').text = flowAPI.translations.t(
    //   `download_my_app.widget.description.${preferredApp}`,
    //   { siteName }
    // );
    $w('#text1').text = flowAPI.translations.t(
      `download_my_app.widget.qr_code_description`,
    );
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      if (!isAppLoaded) {
        isAppLoaded = true;
        const [
          {
            title: siteName,
            url,
            metaSiteId,
            preferredApp,
            androidOwnerPreferredApp,
            iosOwnerPreferredApp,
            ownerPreferredApp,
          },
          designSettings,
        ] = await Promise.all([getSiteName(flowAPI), getAppImages(flowAPI)]);
        $w('#phoneInput2').appUrl = url;
        $w('#badges2').metaSiteId = metaSiteId;

        const app = preferredAppToKey(preferredApp);
        $w('#badges2').androidOwnerPreferredApp = androidOwnerPreferredApp;
        $w('#badges2').iosOwnerPreferredApp = iosOwnerPreferredApp;
        $w('#badges2').ownerPreferredApp = ownerPreferredApp;
        $w('#badges2').preferredApp = preferredApp;

        $w('#phoneInput2').preferredApp = app;

        $w('#device1').logoImageUrl = designSettings.logoImageUrl || '';
        $w('#device1').showLogoInHeader = designSettings.showLogoInHeader;
        $w('#device1').primaryColor = designSettings.primaryColor;
        let [coverImageUrl = ''] = designSettings.coverImagesUrls || [];
        if (coverImageUrl !== '') {
          coverImageUrl = resizeImage(coverImageUrl, 205, 150);
          $w('#device1').coverImageUrl = coverImageUrl;
        }
        $w('#device1').siteName = designSettings.headerTitle || siteName;
        $w('#phoneInput2').siteName = designSettings.headerTitle || siteName;

        data = {
          preferredApp: app,
          siteName: designSettings.headerTitle || siteName,
          designSettings,
          metaSiteId,
          androidOwnerPreferredApp,
          iosOwnerPreferredApp,
        };
        bi(flowAPI).sympAppLoaded();
        setText();
        isAppLoaded = true;
      } else {
        bi(flowAPI).sympAppLoaded();
      }
    },
    exports: {},
  };
});
