import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getPrefixApi } from './utils';
import { getCode } from './getCode';
import { DEFAULT_APP } from '../constants';
import { getPreferredApp } from './getPreferredApp';

let code = '';
let returnData = {
  title: '',
  url: '',
  metaSiteId: '',
  preferredApp: DEFAULT_APP,
  androidOwnerPreferredApp: '',
  iosOwnerPreferredApp: '',
  ownerPreferredApp: '',
};
export interface APISiteNameResponse {
  title: string;
  url: string;
  metaSiteId: string;
  preferredApp: string;
}

export const getSiteName = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    if (returnData.url !== '') {
      return returnData;
    }

    const prefix = getPrefixApi(flowApi);
    const headers = flowApi.controllerConfig
      ? {
          headers: {
            Authorization: flowApi.controllerConfig.appParams.instance,
          },
        }
      : {};
    if (code === '') {
      const codeData = await getCode(flowApi);
      code = codeData.code;
    }
    const [
      { data },
      {
        preferredApp,
        androidOwnerPreferredApp,
        iosOwnerPreferredApp,
        ownerPreferredApp,
      },
    ] = await Promise.all([
      flowApi.httpClient.get<APISiteNameResponse>(
        `${prefix}/clubs/api/v1/invites/details`,
        {
          headers,
          params: {
            code
          },
        },
      ),
      getPreferredApp(flowApi),
    ]);

    returnData = {
      ...data,
      androidOwnerPreferredApp,
      iosOwnerPreferredApp,
      ownerPreferredApp,
      preferredApp,
    };
    return returnData;
  } catch (err) {
    return {
      title: '',
      url: '',
      metaSiteId: '',
      preferredApp: DEFAULT_APP,
      androidOwnerPreferredApp: '',
      iosOwnerPreferredApp: '',
      ownerPreferredApp: '',
    };
  }
};

export default getSiteName;
