import { getSiteName } from '../../api';
import { generateQRCode } from '../../api/utils/qr-code';
import model from './model';

let counter = 0;
export default model.createController(({ $w, $widget, flowAPI }) => {
  const { t } = flowAPI.translations;
  const getQRCode = async (url: string) => {
    if (url === '') {
      const data = await getSiteName(flowAPI);
      if (data.url !== '') {
        url = data.url;
      }
    }
    if (url !== '') {
      const qrCode = generateQRCode(url);
      if (qrCode) {
        $widget.props.qrCode = qrCode;
        $w('#imageX1').src = qrCode;
      }
    }
    return url;
  };
  return {
    pageReady: async () => {
      let url = '';
      $w('#imageX1').alt = t('download_my_app.widget.qr.alt');

      if (counter++ === 0) {
        url = await getQRCode(url);
      }

      setTimeout(async () => getQRCode(url), 2000);
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
